import request from "@/utils/request";

// 进度列表
export function listByAlarm(data: any) {
  return request({
    url: "/project/projectProcess/listByAlarm",
    method: "get",
    params: data,
  });
}

// 进度详情
export function progressDetail(id: any) {
  return request({
    url: `/project/projectProcess/progressDetail/${id}`,
    method: "get",
  });
}
